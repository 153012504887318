// First, select the page element
// const page = document.querySelector('#homebody');

// // Then, define the animation using GSAP
// const animation = gsap.to(page, {
//   opacity: 0,
//   duration: 1,
// });

// // Finally, add an event listener to the link(s) that trigger the animation
// const links = document.querySelectorAll('a');
// links.forEach(link => {
//   link.addEventListener('click', event => {
//     event.preventDefault(); // prevent the default link behavior
//     animation.play(); // play the animation
//     setTimeout(() => {
//       window.location.href = link.href; // go to the clicked link after the animation finishes
//     }, 1000);
//   });
// });

// function showCenteredDiv() {
//     const centeredDiv = document.getElementById('centered-div');
  
//     // Create the animation
//     const fadeIn = gsap.timeline({
//       onComplete: function() {
//         centeredDiv.classList.remove('hidden');
//       }
//     });
//     fadeIn.to(centeredDiv, { duration: 1, opacity: 1 });
//   }
  
//   window.addEventListener('load', function() {
//     showCenteredDiv();
//   });






var tml = gsap.timeline({ defaults: { duration: 1 } })
tml.fromTo("body", { opacity: 0, backgroundColor: 'black' }, { opacity: 1,  backgroundColor: 'black', ease: "Power1.easeInOut", delay: 1 },)
// tml.fromTo("#centered-div", { visibility:'block', opacity: 1, backgroundColor: 'black',  }, { visibility:'hidden', height:'0', opacity: 0, padding:'0',  backgroundColor: 'black', ease: "Power1.easeInOut", delay: 1.5 },)
tml.fromTo("#centered-div", { opacity: 1, backgroundColor: 'black', height:'100'  }, { visibility:'hidden', height:'0', opacity: 0,  backgroundColor: 'black', ease: "Power1.easeInOut", delay: 2.5 },)
tml.fromTo("body, .section_home-header1", { backgroundColor: 'black' }, { opacity: 1, ease: "Power1.easeInOut" }, "-=1")
    .fromTo(".nav_component", { opacity: 0, y: -100 }, { opacity: 1, y: 0, stagger: 0.1, ease: "Power1.easeInOut" }, "-=1")
    .fromTo(".nav_logo", { rotate: 0 }, { rotate: 360, ease: "Power1.easeInOut" }, "-=0.6")
    .fromTo(".header_wrapper", { opacity: 0, x: 50 }, { opacity: 1, ease: "Power1.easeInOut", x: 0 }, "-=0.8")
    .fromTo(".header_lottie-component", { opacity: 1, x: -1500, y: 500 }, { opacity: 1, ease: "Power1.easeInOut", x: 10, y: 0 },)



let inttl = gsap.timeline({ defaults: { duration: 1 } })

inttl.fromTo(".home-intro_heading", { opacity: 0, y: 100 }, { opacity: 1, ease: "Power.easeInOut", y: 0, stagger: 0.1 }, "-=0.10")
    .fromTo(".intro_image-container", { opacity: 1, clipPath: 'polygon(0 0, 100% 0, 100% 0, 0 0)' }, { opacity: 1, clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)' }, "-=0.9")
    .fromTo(".home-intro_text-container", { opacity: 0, y: 100 }, { opacity: 1, ease: "Power2.easeInOut", y: 0, stagger: 0.1 }, "-=0.8")
    .fromTo(".intro_button-container", { opacity: 0, y: 100 }, { opacity: 1, ease: "Power2.easeInOut", y: 0, stagger: 0.1 }, "-=0.7")


ScrollTrigger.create({
    trigger: '.home-header_btn-container',
    start: "top 20%",
    end: "+=500",
    animation: inttl,
    // scrub: true, //enterback
    // events: onEnter onLeave onEnterBack onLeaveBack
    // toggleActions: "restart play reverse reset"
});


let mctl = gsap.timeline({ defaults: { duration: 1 } })

mctl.fromTo(".marquee-scroll", { x: -300, }, { opacity: 1, x: 0, ease: "Power.easeInOut" }, "-=0.2")
    // .fromTo("#eye1", { opacity: 1, x: -1500, }, { opacity: 1, x: 0, scale: 1, ease: "Power.easeInOut" },)
    // .fromTo("#eye2", { opacity: 1, x: -1500, }, { opacity: 1, x: 0, scale: 1, ease: "Power.easeInOut" }, "-=1")

// hcrs.fromTo("#emb", { opacity: 0, y: 500 }, { opacity: 1, y: 0, ease: "Power.easeInOut", stagger: 0.1 }, "-=0.8")


ScrollTrigger.create({
    trigger: '.intro_image-container',
    start: "bottom 80%",
    end: "+=2000",
    animation: mctl,
    scrub: true, //enterback
    scrub: 1,
});





let hcrs = gsap.timeline({ defaults: { duration: 1 } })

hcrs.fromTo(".course-about_wrapper", { opacity: 0, x: 300, }, { opacity: 1, x: 0, ease: "Power.easeInOut" }, "-=0.2")
hcrs.fromTo("#eye1", { opacity: 1, x: -1500, }, { opacity: 1, x: 0, scale: 1, ease: "Power.easeInOut" },)
hcrs.fromTo("#eye2", { opacity: 1, x: -1500, }, { opacity: 1, x: 0, scale: 1, ease: "Power.easeInOut" }, "-=1")

// hcrs.fromTo("#emb", { opacity: 0, y: 500 }, { opacity: 1, y: 0, ease: "Power.easeInOut", stagger: 0.1 }, "-=0.8")


ScrollTrigger.create({
    trigger: '.course_heading',
    start: "top 70%",
    end: "+=500",
    animation: hcrs,
    // scrub: true, //enterback
    // events: onEnter onLeave onEnterBack onLeaveBack
    // toggleActions: "restart play reverse reset"
});



let swptl = gsap.timeline({ defaults: { duration: 1 } })

swptl.fromTo(".swiper-slide , .swiper-arrow , .slider-prev , .slider-next", { opacity: 0, y: 800 }, { opacity: 1, y: 0, ease: "Power.easeInOut" }, "-=0.2")

ScrollTrigger.create({
    trigger: '.swiper',
    start: "top 60%",
    end: "+=500",
    animation: swptl,
    // scrub: true, //enterback
    // events: onEnter onLeave onEnterBack onLeaveBack
    // toggleActions: "restart play reverse reset"
});




let stcdtl = gsap.timeline({ defaults: { duration: 1 } })


stcdtl.fromTo(".section_home-structure", { opacity: 0, y: 500 }, { opacity: 1, y: 0, ease: "Power.easeInOut", stagger: 0.1 },)

ScrollTrigger.create({
    trigger: '.section_home-structure',
    start: "0% 100%",
    end: "+=500",
    animation: stcdtl,
    // scrub: true, //enterback
    // events: onEnter onLeave onEnterBack onLeaveBack
    // toggleActions: "restart play reverse reset"
});








let stcd = gsap.timeline({ defaults: { duration: 1 } })

stcd.fromTo("#webd", { opacity: 0, y: 300 }, { opacity: 1, y: 0, ease: "Power.easeInOut", stagger: 0.1 }, "-=0.10")
stcd.fromTo("#aut", { opacity: 0, y: 400 }, { opacity: 1, y: 0, ease: "Power.easeInOut", stagger: 0.1 }, "-=0.9")
stcd.fromTo("#emb", { opacity: 0, y: 500 }, { opacity: 1, y: 0, ease: "Power.easeInOut", stagger: 0.1 }, "-=0.8")

ScrollTrigger.create({
    trigger: '#cut',
    start: "top 50%",
    end: "+=500",
    animation: stcd,
    // scrub: true, //enterback
    // events: onEnter onLeave onEnterBack onLeaveBack
    // toggleActions: "restart play reverse reset"
});





let testscr = gsap.timeline({ defaults: { duration: 1 } })

testscr.fromTo("#tscd", { opacity: 1, y: 0, }, { opacity: 1, y: 400, ease: "Power.easeInOut" },)
// testscr.fromTo(".relative-bottom", { opacity: 1, y:0, }, { opacity: 1, y:400 ,ease: "Power.easeInOut" },)
// testscr.fromTo("#eye2", { opacity: 1, x:1000, scale:20 }, { opacity: 1, x:0, scale:1 ,ease: "Power.easeInOut"}, "-=1")
// hcrs.fromTo("#emb", { opacity: 0, y: 500 }, { opacity: 1, y: 0, ease: "Power.easeInOut", stagger: 0.1 }, "-=0.8")

ScrollTrigger.create({
    trigger: '.testimonial_heading',
    start: "top 30%",
    end: "+=2000",
    animation: testscr,
    scrub: true, //enterback
    scrub: 1,
    // events: onEnter onLeave onEnterBack onLeaveBack
    // toggleActions: "restart play reverse reset"
});


